<!--
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-09-14 09:33:27
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2022-11-11 08:04:51
 * @FilePath: \ding-lims\src\views\Report.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report">
    <div id="printMe">
      <h3 class="tc">{{dataList.typeName}}化验报告单</h3>
      <van-row>
        <van-col span="12">原料名称：{{dataList.sample_name }}</van-col>
        <van-col span="12">来料单位：{{dataList.supplier_name}}</van-col>
      </van-row>
      <table class="table-cle" style="margin-top: 10px">        
        <tr>
          <td colspan="3">批号：{{dataList.inspect_batch}}</td>
          <td colspan="2">采样人：{{dataList.inspect_emp_name}}</td>
          <td colspan="2">数量：{{dataList.inspect_num}}</td>
        </tr>
      </table>     
      <ve-table class="item" style="margin-top: -1px;border: 1px solid #000;"
        :columns="proColumns"
        :table-data="dataList.checkItems"
        :border-around="true"
        :border-x="true"
        :border-y="true"
        :cell-span-option="cellSpanOption"        
      />
      <table class="table-cle" style="margin-top: -1px">
        <tr>
          <td colspan="7">报检备注：{{dataList.inspect_remark}}</td>
        </tr>
        <tr>
          <td colspan="7">初检备注：{{dataList.check_remark}}</td>
        </tr>        
      </table>
      <van-row class="mt10">
        <van-col span="8">检验人：{{dataList.check.check_emp_name}}</van-col>
        <van-col span="8">复检人：{{dataList.check.recheck_emp_name}}</van-col>
        <van-col span="8">化验时间：{{dataList.check.recheck_time}}</van-col>
      </van-row>
    </div>
    <div class="mt10 btn-r">
      <van-button class="ml5" round plain size="small" type="default" @click="back">返回</van-button>
      <van-button class="ml5" round plain size="small" type="default" v-print="print">立即打印</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Col, Row } from 'vant'

Vue.use(Toast)
Vue.use(Col)
Vue.use(Row)

export default {
  data() {
    const that = this
    return {
      id: '',
      cellSpanOption: {
        bodyCellSpan: this.bodyCellSpan,
      },
      proColumns: [
        { field: "check_name", key: "a", title: "检测项", align: "left" },
        { field: "check_standard", key: "b", title: "参考指标", align: "center" },
        { field: "check_value", key: "c", title: "检测指标", align: "center" },
        { field: "single_conclusion", key: "d", title: "单项结论", align: "center" },
        { field: "conclusion", key: "e", title: "结论", align: "center" }
        ],
      dataList: {
        checkItems: [],
        check: { }
      },
      print: {
        id: 'printMe',
        popTitle: '', // 打印配置页上方的标题
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: '报告单打印', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 999, // 预览窗口的z-index，默认是20002，最好比默认值更高
        standard: '',
        extarCss: '',
        previewBeforeOpenCallback () { 
          // console.log('正在加载预览窗口！')
        }, // 预览窗口打开之前的callback
        previewOpenCallback () { 
          // document.querySelector('#printMe').setAttribute('style','height: 0 !important')
        }, // 预览窗口打开时的callback
        beforeOpenCallback () { 
          // console.log('开始打印之前！') 
        }, // 开始打印之前的callback
        openCallback() { 
          // console.log('执行打印了！') 
        }, // 调用打印时的callback
        closeCallback () { 
        }, // 关闭打印的callback(无法区分确认or取消)
        clickMounted () {
          // document.querySelector('#printMe').setAttribute('style','height: auto !important')
        },
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    if (this.id) {
      this.getDetail()
    } else {
      this.$router.push({ path: '/403' })
    }
  },
  methods: {
    getDetail() {
      const that = this
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.$post('inspect/get-inspect-detail', 'api', {
        id: this.id
      }).then(res => {
        const data = res.data
        data.typeName = data.type.type_name
        data.checkItems = data.check.check_items
        data.checkItems.forEach(ele => {
          if (ele.is_concession_acceptance == '1') {
            if (ele.conclusion == '0') {
              ele.conclusion = '不合格[让步]'
            } else {
              ele.conclusion = '合格[让步]'
            }
          } else {
            if (ele.conclusion == '0') {
              ele.conclusion = '不合格'
            } else {
              ele.conclusion = '合格'
            }
          }
          if (ele.single_conclusion == '0') {
            ele.single_conclusion = '不合格'
          } else {
            ele.single_conclusion = '合格'
          }
        })
        data.inspect_time = that.$common.initDate(data.inspect_time)
        data.check.check_time = that.$common.initDate(data.check.check_time)
        data.concession_acceptance_time = that.$common.initDate(data.concession_acceptance_time)
        data.inspect_recheck_time = that.$common.initDate(data.inspect_recheck_time)
        data.check.recheck_time = that.$common.initDate(data.check.recheck_time)
        this.dataList = data
        Toast.clear()
      }, err => {
        console.log(err)
      })
    },
    back() {
      this.$router.push({ path: '/sampledetail', query:{ id: this.id} })
    }
  }
}
</script>
<style scoped>
h3{
  padding: 10px 0;
}
.report{
  min-width: 750px;
  font-size: 14px;
  background: #FFFFFF;
  min-height: 100vh;
}
.table-cle{
  width: 100%;
  border-collapse: collapse;
  border: solid 1px #1a1a1a;
}
.table-cle td {
  text-align: left;
  border: solid 1px #1a1a1a;
  padding: 0px 2px;
  text-align: left;
}
.item{
  border: solid 1px #1a1a1a;
  display: block !important;
}
.item >>> .ve-table-content td,
.item >>> .ve-table-content th{
  border: solid 1px #4d4d4d;
  border-left: 0;
  border-top: 0;
}
.report >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header tr.ve-table-header-tr {
  height: 20px !important;
}
.report >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th { 
  padding: 0px 10px !important;
}


@media print {
  #printMe {
    height: auto;
    display: block !important;
  }  
}
/*去除页眉页脚*/
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}
html{
  background-color: #FFFFFF;
  margin: 0;  /* this affects the margin on the html before sending to printer */
}
#printMe {
  overflow: hidden;
  padding: 0 10px !important;
}
#printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header tr.ve-table-header-tr, #printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr, #printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr {
  height: 20px !important;
}
#printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th,#printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td,#printMe >>> .ve-table .ve-table-container .ve-table-content-wrapper table.ve-table-content tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-body-td {
  padding: 0 10px !important;
}
/*去除页眉页脚*/
  @page{
    size:  auto;   /* auto is the initial value */
    margin: 3mm;  /* this affects the margin in the printer settings */
  }
 
  html{
    background-color: #FFFFFF;
    margin: 0;  /* this affects the margin on the html before sending to printer */
  }
</style>